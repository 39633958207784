import React from "react";
import "./faqs.css";
import { Accordion } from "../../components";

const FAQs = () => {
  return (
    <div className="faqs_section" id="faqs">
      <div className="accordion_container">
        <h2>Questions les plus fréquentes (FAQs)</h2>
        <div className="accordions">
          <Accordion
            headline="Avez-vous une version d'essaie ?"
            button_text=">"
            answer="This is an answer to a frequently asked question. A couple more words to specify the subject further, provide more detail and make sure the user understands everything."
          />
          <Accordion
            headline="Comment puis-je avoir une démo ?"
            button_text=">"
            answer="This is an answer to a frequently asked question. A couple more words to specify the subject further, provide more detail and make sure the user understands everything."
          />
          <Accordion
            headline="Comment pouvons-nous vous contactez ?"
            button_text=">"
            answer="This is an answer to a frequently asked question. A couple more words to specify the subject further, provide more detail and make sure the user understands everything."
          />
          <Accordion
            headline="Avez-vous des tutoriels de formation ?"
            button_text=">"
            answer="This is an answer to a frequently asked question. A couple more words to specify the subject further, provide more detail and make sure the user understands everything."
          />
          <Accordion
            headline="Quels sont vos tarifs commercials ?"
            button_text=">"
            answer="This is an answer to a frequently asked question. A couple more words to specify the subject further, provide more detail and make sure the user understands everything."
          />
        </div>
      </div>
    </div>
  );
};

export default FAQs;
