import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const MetaTags = ({ namespace }) => {
  const { t } = useTranslation([namespace]);

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta name="description" content={t('business_description')} />
    </Helmet>
  );
};

export default MetaTags;