import React from "react";
import "./software.css";

const Software = ({ leftContent, rigthContent, leftwWidth, rigthWidth }) => {
  return (
    <div className="software_item_container">
      <div className="leftContent">{leftContent}</div>
      <div className="rigthContent">{rigthContent}</div>
    </div>
  );
};

export default Software;
