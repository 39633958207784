import React from "react";
import "./benefits.css";
import { useTranslation } from "react-i18next";
import Benefit from "../benefit/Benefit";

const Benefits = () => {
  const { t } = useTranslation(["home"]);
  return (
    <div className="quitaye-benefits">
      <h2>{t("benefit_title")}</h2>
      <div className="quitaye-benefits__container">
        <Benefit
          title={t("benefit1_title")}
          icon="🌿"
          description={t("benefit1_description")}
        />
        <Benefit
          title={t("benefit2_title")}
          icon="⚙️"
          description={t("benefit2_description")}
        />
        <Benefit
          title={t("benefit3_title")}
          icon="📊"
          description={t("benefit3_description")}
        />
        <Benefit
          title={t("benefit4_title")}
          icon="💰"
          description={t("benefit4_description")}
        />
        <Benefit
          title={t("benefit5_title")}
          icon="📑"
          description={t("benefit5_description")}
        />
        <Benefit
          title={t("benefit6_title")}
          icon="📈"
          description={t("benefit6_description")}
        />
        <Benefit
          title={t("benefit7_title")}
          icon="😊"
          description={t("benefit7_description")}
        />
        <Benefit
          title={t("benefit8_title")}
          icon="🚀"
          description={t("benefit8_description")}
        />
      </div>
    </div>
  );
};

export default Benefits;
