import React from "react";
import "./button.css";
import { Link } from "react-router-dom";

const Button = ({ text, url, onClick }) => {
  return (
    <button className="button" onClick={onClick}>
      <h4>
        <Link to={url}>{text}</Link>
      </h4>
    </button>
  );
};

export default Button;
