import React, {useEffect} from 'react';
import BusinessSchema from './businessSchema';
import {FAQs, Footer, Header, Navbar, Stats, CTA, Benefits, MetaTags} from './home/components';
import {Softwares, Testimonials} from './home/containers';
import ReactGa from 'react-ga';
import './app.css';

const App = () => {

  useEffect(() => {
    ReactGa.pageview('/');
  }, []);
  return (
    <div>
      <div>
        <Navbar/>
        <MetaTags namespace="home" />;
        <BusinessSchema/>
        <Header/>
      </div>
        <Stats />
        <Softwares />
        <Benefits />
        
        <CTA />
        <FAQs />
        <Footer />
    </div>
  )
}

export default App
