import React from "react";
import "./textContent.css";
import { NoBackgroundButton } from "../../components";

const TextContent = ({ title, url, description, buttonText, onClick }) => {
  return (
    <div className="textContent_container">
      <h3>{title}</h3>
      <p class="txt">{description}</p>
      <NoBackgroundButton url={url} text={buttonText} onClick={onClick} />
    </div>
  );
};

export default TextContent;
