import React from "react";
import "./cta.css";
import { Button } from "../../components";

const CTA = () => {
  return (
    <div className="cta_section">
      <div className="cta_container">
        <div className="cta_container-content">
          <h2>Prêt à accepter le changement ?</h2>
          <p>
            Débuter votre aventure vers le succès avec nous. Connectez-vous pour
            une demonstration personnalisée dès aujourd'hui
          </p>
        </div>
        <Button text="Obtenez une demo" url="/contact" />
      </div>
    </div>
  );
};

export default CTA;
