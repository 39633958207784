import React from 'react';
import './noBackgroundButton.css';
import {Link} from 'react-router-dom';

const NoBackgroundButton = ({text, url}) => {
  return (
    <div className="nobackground_button">
        <h4><Link to={url}>{text}</Link></h4>
    </div>
  )
}

export default NoBackgroundButton