import React from 'react';
import './logo.css';
import image from '../../../assets/Logo.svg';

const Logo = () => {
  return (
    <div className="logo_container">
        <a href="/"><img src={image} alt="logo" /></a>
        <a href="/"><p>uitaye</p></a>
          
    </div>
  )
}

export default Logo