import React from "react";
import "./contact.css";
import { MainLayout } from "../../components";

const Contact = () => {
  return (
    <MainLayout>
      <div className="page">
        <h1>Contact</h1>
      </div>
    </MainLayout>
  );
};

export default Contact;
