import React from "react";
import "./stats.css";
import { StatItem } from "../../components";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const { t } = useTranslation(["home"]);
  return (
    <div className="stats_container">
      <div className="stats_container-section">
        <StatItem data={t("state_item_data1")} title={t("state_item_title1")} />
        <StatItem data={t("state_item_data2")} title={t("state_item_title2")} />
        <StatItem data={t("state_item_data3")} title={t("state_item_title3")} />
      </div>
    </div>
  );
};

export default Stats;
