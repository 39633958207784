import React from "react";
import { useTranslation } from "react-i18next";

const BusinessSchema = () => {
  const { t } = useTranslation();
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Quitaye",
    url: "Quitaye",
    description: t("business_description"),
    address: {
      "@type": "PostalAddress",
      streetAddress: "123 Main Street",
      addressLocality: "City",
      addressRegion: "State",
      postalCode: "12345",
      addressCountry: "Country",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1-123-456-7890",
      contactType: "Customer service",
    },
  };

  return (
    <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
  );
};

export default BusinessSchema;
