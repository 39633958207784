import React from 'react';
import './statItem.css';

const StatItem = ({data, title}) => {
  return (
    <div className="statItem_container">
        <h2>{data}</h2>
        <p>{title}</p>
    </div>
  )
}

export default StatItem