import React from "react";
import "./socialMedia.css";

const SocialMedia = ({ image_url, link, name }) => {
  return (
    <div className="social_media-container">
      <a href={link} rel="noreferrer" target="_blank">
        <img src={image_url} alt="social media" />
      </a>
    </div>
  );
};

export default SocialMedia;
