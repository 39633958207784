import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { Logo, SocialMedia } from "../../components";

import Facebook from "../../../assets/facebook.svg";
import Youtube from "../../../assets/youtube.svg";
import LinkedIn from "../../../assets/linkedin.svg";
import Tiktok from "../../../assets/tiktok.svg";
import Whatsapp from "../../../assets/whatsapp.svg";

import { Button } from "../../components";

const Footer = () => {
  return (
    <div className="footer_section">
      <div className="footer_container">
        <div className="row1">
          <div className="footer_big-col">
            <div className="logo_content">
              <Logo />
            </div>
            <div className="tagline-social_media">
              <p>Helps Company Manage Their Sales & Finances Easily</p>
              <div className="social_media">
                <SocialMedia
                  link="https://www.facebook.com/Quitaye1"
                  name="Facebook"
                  image_url={Facebook}
                />
                <SocialMedia
                  link="https://www.linkedin.com/company/86879773/admin/feed/posts/"
                  name="LinkedIn"
                  image_url={LinkedIn}
                />
                <SocialMedia
                  link="https://wa.me/22371655833"
                  image_url={Whatsapp}
                  name="Whatsapp"
                />
                <SocialMedia
                  link="https://www.tiktok.com/@quitaye"
                  name="Tiktok"
                  image_url={Tiktok}
                />
                <SocialMedia link="#" image_url={Youtube} name="Youtube" />
              </div>
            </div>
          </div>
          <div className="footer_col">
            <h4>Quitaye</h4>
            <p>
              <Link to="/pricing">Pricing</Link>
            </p>
            <p>
              <Link to="/testimonials">Temoignages</Link>
            </p>
            <p>
              <Link to="/cas-studies">Cas d'étude</Link>
            </p>
            <p>
              <Link to="/blog">Blog</Link>
            </p>
          </div>
          <div className="footer_col">
            <h4>Services</h4>
            <p>
              <Link to="/softwares">Logiciels</Link>
            </p>
            <p>
              <Link to="/mobile-app">Application</Link>
            </p>
            <p>
              <Link to="/web-dev">Developpement Web</Link>
            </p>
            <p>
              <Link to="/server">Serveur</Link>
            </p>
          </div>

          <div className="footer_big-col">
            <h3>Join Our NewsLetter</h3>
            <div className="email">
              <div className="rounded_textField">
                <input type="email" placeholder="Email Adress" />
              </div>
              <Button text="submit" />
            </div>
          </div>
        </div>
        <div className="row2">
          <p>Copyright © Quitaye 2024. Tout Droits Reservés.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
