import React, { useState } from "react";
import "./navbar.css";
import { Logo } from "../../components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dropdown = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className={`dropdown__item ${isOpen ? "show-dropdown" : ""}`}>
      <div className="nav__link dropdown__button" onClick={toggleDropdown}>
        {title}{" "}
        <i
          className={`ri-arrow-down-s-line dropdown__arrow ${
            isOpen ? "rotate" : ""
          }`}
        ></i>
      </div>

      <div className="dropdown__container">
        <div className="dropdown__content">
          {items.map((group, index) => (
            <div className="dropdown__group" key={index}>
              <div className="dropdown__icon">
                <i className={group.icon}></i>
              </div>
              <span className="dropdown__title">{group.title}</span>
              <ul className="dropdown__list">
                {group.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link to={link.url} className="dropdown__link">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </li>
  );
};

const Navbar = () => {
  const logicielsdropdownData = [
    {
      title: "Les plus populaires",
      links: [
        { label: "Quitaye Finance", url: "/quitaye-finance" },
        { label: "Quitaye School", url: "/quitaye-school" },
        { label: "Quitaye Patiss", url: "/quitaye-patiss" },
        { label: "Quitaye Medical", url: "/quitaye-medical" },
      ],
      icon: "ri-flashlight-line",
    },
    {
      title: "Popular courses",
      links: [
        { label: "Development with Flutter", url: "#" },
        { label: "Web development with React", url: "#" },
        { label: "Backend development expert", url: "#" },
      ],
      icon: "ri-heart-3-line",
    },
    {
      title: "Careers",
      links: [
        { label: "Web development", url: "#" },
        { label: "Applications development", url: "#" },
        { label: "UI/UX design", url: "#" },
        { label: "Informatic security", url: "#" },
      ],
      icon: "ri-book-mark-line",
    },
    // Add more dropdown data as needed
  ];

  const servicesdropdownData = [
    {
      title: "Les plus solicités",
      links: [
        { label: "Logiciels de gestion", url: "/softwares" },
        { label: "Application Mobile", url: "/mobile-app" },
        { label: "Developpement Web", url: "/web-dev" },
        { label: "Maintenance Serveur", url: "/server" },
      ],
      icon: "ri-flashlight-line",
    },
    {
      title: "Popular courses",
      links: [
        { label: "Development with Flutter", url: "#" },
        { label: "Web development with React", url: "#" },
        { label: "Backend development expert", url: "#" },
      ],
      icon: "ri-heart-3-line",
    },
    {
      title: "Careers",
      links: [
        { label: "Web development", url: "#" },
        { label: "Applications development", url: "#" },
        { label: "UI/UX design", url: "#" },
        { label: "Informatic security", url: "#" },
      ],
      icon: "ri-book-mark-line",
    },
    // Add more dropdown data as needed
  ];

  const ressourcesdropdownData = [
    {
      title: "Les plus solicités",
      links: [
        { label: "Témoignages", url: "/testimonials" },
        { label: "Cas des clients", url: "/cas-studies" },
        { label: "Blog", url: "/blog" },
      ],
      icon: "ri-flashlight-line",
    },

    // Add more dropdown data as needed
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { t } = useTranslation(["home"]);

  /*=============== SHOW DROPDOWN MENU ===============*/
  const dropdownItems = document.querySelectorAll(".dropdown__item");

  // 1. Select each dropdown item
  dropdownItems.forEach((item) => {
    const dropdownButton = item.querySelector(".dropdown__button");

    // 2. Select each button click
    dropdownButton.addEventListener("click", () => {
      // 7. Select the current show-dropdown class
      const showDropdown = document.querySelector(".show-dropdown");

      // 5. Call the toggleItem function
      toggleItem(item);

      // 8. Remove the show-dropdown class from other items
      if (showDropdown && showDropdown !== item) {
        toggleItem(showDropdown);
      }
    });
  });

  // 3. Create a function to display the dropdown
  const toggleItem = (item) => {
    // 3.1. Select each dropdown content
    const dropdownContainer = item.querySelector(".dropdown__container");

    // 6. If the same item contains the show-dropdown class, remove
    if (item.classList.contains("show-dropdown")) {
      dropdownContainer.removeAttribute("style");
      item.classList.remove("show-dropdown");
    } else {
      // 4. Add the maximum height to the dropdown content and add the show-dropdown class
      dropdownContainer.style.height = dropdownContainer.scrollHeight + "px";
      item.classList.add("show-dropdown");
    }
  };
  return (
    <header className="header">
      <nav className="nav container">
        <div className="nav__data">
          <a href="/" className="nav__logo">
            <Logo />
          </a>
          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <i
              className={`ri-menu-line ${
                isMenuOpen ? "show-icon" : ""
              }_nav__toggle-menu`}
            ></i>
            <i
              className={`ri-close-line ${
                isMenuOpen ? "show-icon" : ""
              }_nav__toggle-close`}
            ></i>
          </div>
        </div>
        <div
          className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <Dropdown title="Softwares" items={logicielsdropdownData} />
            <Dropdown title="Services" items={servicesdropdownData} />
            <Dropdown title="Ressources" items={ressourcesdropdownData} />

            <li>
              <Link to="/contact" className="nav__link_button">
                {t("main_cta")}
              </Link>
            </li>
            {/* Add more menu items or dropdowns */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
