import React from 'react';
import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import ReactDOM from 'react-dom';
import NoPage from './NoPage/NoPage';
import App from './App';
import './index.css';
import {PricingPage} from './pricing/containers';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Contact } from './home/components';
import i18n from './i18n';
import MetaTags from './home/components/MetaTags'; // Import the MetaTags component


const router = createBrowserRouter([
  {
    path:"/",
    element: <App/>
  },
  {
    path:"contact",
    element: <Contact/>
  },
  {
    path:"*",
    element: <NoPage/>
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading...">
      <I18nextProvider i18n={i18n}>
        <MetaTags /> {/* Include the MetaTags component */}
        <RouterProvider router={router} />
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);