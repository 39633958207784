import React from "react";
import "./softwares.css";
import { Software, TextContent, ImageContent } from "../../components";
import WomenWithMoney from "../../../assets/women with money.jpg";
import SupermarketStock from "../../../assets/Supermarket Stock.jpg";
import WomenHandleTax from "../../../assets/women handle Tax.jpg";
import office_desk from "../../../assets/laptop-chart.jpg";
import { useTranslation } from "react-i18next";

const Softwares = () => {
  const onleftClick = () => {
    console.log("On left click");
  };

  const onrighttClick = () => {
    console.log("On right click");
  };

  const { t } = useTranslation(["home"]);

  return (
    <div className="softwares_section">
      <div className="softwares_container-section">
        <h2>{t("offers_header")}</h2>
        <div className="software_container">
          <Software
            rigthContent={<ImageContent image_url={SupermarketStock} />}
            leftContent={
              <TextContent
                title={t("Efficient_Stock_Control")}
                url="/"
                description={t("Efficient_Stock_Control_Description")}
              />
            }
          />
          <Software
            leftContent={<ImageContent image_url={WomenHandleTax} />}
            rigthContent={
              <TextContent
                title={t("Compliance_Assurance")}
                url="/"
                description={t("Compliance_Assurance_Description")}
              />
            }
          />
          <Software
            rigthContent={<ImageContent image_url={WomenWithMoney} />}
            leftContent={
              <TextContent
                title={t("Secure_Cash_Handling")}
                url="/"
                description={t("Secure_Cash_Handling_Description")}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Softwares;
