import React from "react";
import "./header.css";
import hero from "../../../assets/Quitaye_Software_Insight.png";
import { Button } from "../../components";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation(["home"]);

  return (
    <div className="quitaye-header">
      <div className="quitaye-header__container">
        <div className="quitaye-header__container-left">
          <h1>{t("mainH1")}</h1>
          <p>{t("description")}</p>
          <Button url="/softwares" text={t("main_cta")} />
        </div>
        <div className="quitaye-header__container-right">
          <img src={hero} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
