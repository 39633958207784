import React from "react";
import "./noPage.css";
import { MainLayout } from "../home/components";

const NoPage = () => {
  return (
    <MainLayout>
      <div class="no_page">
        <h2>Error: page not exist</h2>
      </div>
    </MainLayout>
  );
};

export default NoPage;
