import React from 'react';
import './imageContent.css';

const ImageContent = ({image_url}) => {
  return (
    <div className="imageContent_container">
        <img src={image_url} alt="software" />
    </div>
  )
}

export default ImageContent