import React, { useState } from "react";
import "./accordion.css";

const Accordion = ({ headline, button_text, answer, url }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleButtonClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="accordion_item_container">
      <div className="question">
        <h3>{headline}</h3>
        <button
          className={`btn ${isFlipped ? "flipped" : ""}`}
          onClick={handleButtonClick}
        >
          <p>{button_text}</p>
        </button>
      </div>
      <div className={`${isFlipped ? "show" : "hide"}`}>
        <div className="line" />
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default Accordion;
