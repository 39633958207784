import React from "react";
import "./benefit.css";

const Benefit = ({ icon, title, description }) => {
  return (
    <div className="benefit">
      <div className="icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Benefit;
